import { React } from 'react';
import { Helmet } from 'react-helmet';
import { Copyright } from '../components/Copyright.js';
import { Form } from 'react-router-dom';

// woven light brown #9D8B7F
// woven dark brown #342C28

export const Layout = ({ children }) => {
    return (        
        <div>
            <Helmet>
                <title>Woven Analytics</title>
                <meta name="description" content="Get the best mining analytics all in one place!" />
                <meta name="viewport" content="initial-scale=1, width=device-width" />
                <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"/>
            </Helmet>
            <header>
                <h1>Woven Analytics Data Platform</h1>
            </header>
            <main>
                {children}
            </main>
            <footer>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </footer>
        </div>
    );
}