import * as React from 'react';
import { useState } from 'react';
import Copyright from '../components/Copyright.js';
import { Navigate } from 'react-router-dom';
import { Layout } from '../components/Layout.js';
import { useAuth } from '../auth.js';

// props destructured into items
export default function SignIn({supabase}) {       

    const auth = useAuth()

    // use supabase OAuth to sign in
    async function handleSubmit(e) {
        e.preventDefault();
        supabase.auth.signInWithOAuth({ provider: 'google' });
    }

    const [loading, setLoading] = useState(false)    
    const [email, setEmail] = useState('')
  
    const handleLogin = async (e) => {
      e.preventDefault()
      try {
        setLoading(true)
        const { error } = await supabase.auth.signInWithOtp({ email })
        if (error) throw error
        alert('Check your email for the login link!')
      } catch (error) {
        alert(error.error_description || error.message)
      } finally {
        setLoading(false)
      }
    }

    return (        
        <Layout>
            <div className="row flex-center flex">
                <div className="col-6 form-widget" aria-live="polite">
                    <h1 className="header">Please Login to Access the Portal</h1>
                    <p className="description">Sign in via magic link with your email below</p>
                    {loading ? (
                    'Sending magic link...'
                    ) : (
                    <form onSubmit={handleLogin}>
                        <label htmlFor="email">Email</label>
                        <input
                        id="email"
                        className="inputField"
                        type="email"
                        placeholder="Your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        />
                        <button className="button block" aria-live="polite">
                        Send magic link
                        </button>
                        <button onClick={handleSubmit}>Sign In With Google</button>
                    </form>
                    )}
                </div>
            </div>            
        </Layout>
    );
}