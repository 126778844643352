import * as React from 'react';
import { redirect } from 'react-router-dom';
import { Layout } from '../components/Layout.js';

const DataSearch = ({supabase, session}) => { 
  
  if (session === null) {
    return redirect("/");
  }

  return (    
    <Layout>
      <h1>Search Page</h1>
    </Layout>
  );
}

export default DataSearch;