import {useAuth} from "../auth";
import {Navigate} from 'react-router-dom';

// This component is used to protect routes that require authentication

const ProtectedRoute = ({children}) => {
    const auth = useAuth()
    return auth.user ? children : <Navigate to={"/"}/>
}

export default ProtectedRoute;