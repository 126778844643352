import React from 'react';
import ReactDOM from 'react-dom/client';
import SignIn from './pages/SignIn.js';
import DataSearch from './pages/DataSearch.js';
import reportWebVitals from './reportWebVitals';
import ProtectedRoute from "./components/ProtectedRoute";
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { supabase } from './Supabase.js';
import { AuthProvider } from './auth.js';
import { Auth } from '@supabase/auth-ui-react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    //<React.StrictMode>
    //<AuthProvider>
        <BrowserRouter>
            <Routes>                
                <Route path="/" element={<SignIn supabase={supabase} />} />
                <Route path="/search" element={<ProtectedRoute><DataSearch supabase={supabase} /></ProtectedRoute>} />
                <Route path="*" element={<div>Error 404: What did you do??</div>} />
            </Routes>
        </BrowserRouter>
    //</AuthProvider>    
    //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();