import { RealtimeChannel, Session } from "@supabase/supabase-js";
import { supabase } from "./Supabase.js";
import {useState, useEffect, useContext, createContext} from "react";

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...

export const AuthProvider = ({children}) => {
    const auth = useProvideAuth()
    return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

// Hook for child components to get the auth object ...

export const useAuth = () => {
    return useContext(authContext)
}

// creates auth object and handles state ...

function useProvideAuth() {
    const [user, setUser] = useState(null)

    const login = async (email) => {
        const {error, user} = await supabase.auth.signIn({email})

        if(error) {
            console.log(error)
        }

        return {error, user}
    }

    const logout = async () => {
        const {error} = await supabase.auth.signOut()

        if(error) {
            console.log(error)
        }

        setUser(null)
    }

    useEffect(() => {
        const user = supabase.auth.user()
        setUser(user)

        const auth = supabase.auth.onAuthStateChange((event, session) => {
            if (event === 'SIGNED_IN') {
                setUser(session.user)
            }

            if (event === 'SIGNED_OUT') {
                setUser(null)
            }
        })

        return () => auth.data.unsubscribe()

    }, [])

    return {
        user,
        login,
        logout
    }

}